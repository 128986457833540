import React, { useEffect, useState } from "react";
import "./Registermandate.scss";
import logo from "../../../Images/TCL-newlogo.jpg";
import logoImage from "../../../Images/eNachNPCIlogo.png";
import autoPay from "../../../Images/UPI_Autopay.svg";
import { useParams } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import { toast } from "react-toastify";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import upiLogo from '../../../Images/upi-ar21.svg'
import qrCode from '../../../Images/QR-svg.svg'
import phonePay from '../../../Images/icons8-phone-pe.svg'
import googlePay from '../../../Images/google-pay.svg'
import bhimUPI from '../../../Images/bhim.svg'
import paytmLogo from '../../../Images/icons8-paytm.svg'
import {
  Button as ButtonReact,Alert
} from "react-bootstrap";

// import Math from 'Math'
import {
  EnachStatusCode,
  accountType,
  StatusCodeFlags,
  commonVariables,
  YupMessages,
  termsAndConditions,
  aadharConditions,
  AuthModes,
  EnachUPIPaymentModes,
} from "../../../constants/Common/commonConstants";
import { tpslConfig } from "../../../config/configJson";
import FormLabel from "../../../component/common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikSelect from "../../../component/common/formikSelect/formikSelect";
import * as Yup from 'yup';
import { object, string } from "yup";
import {
  DtRepresentationTableOrder,
  EnachVendorTypes,
  apiConstants,
  bankListAttributes,
  enachApiConstants,
  enachTechProcessConstants,
  errMessage,
  mandateTypeAttributes,
  manualMandateAttributes,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import ModalComponent from "../../../component/common/modal";
import moment from "moment/moment";
import { Modal, Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { webSocketData } from "../../../redux/reducer/webSocketReducer";
import { isAuthModeActive } from "../../../utils/utils";
const getOS = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes("Windows NT")) return "Windows";
  if (userAgent.includes("X11") || userAgent.includes("Linux")) return "Linux";
  if (userAgent.includes("Android")) return "Android";
  if (userAgent.includes("Mac OS") || userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod") || userAgent.includes("i")) return "ios";
  return "Unknown";
};

const Time = []

const Registermandate = () => {
  const [auth, setAuth] = useState(false);
  const [decodedVal, setDecodedVal] = useState("");
  console.log(decodedVal, "decodeVal");
  const [radioVal, setRadioVal] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [source_system, setSourceSystem] = useState("");
  const [nupayFlag, setNupayFlag] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [webTop, setWebTop] = useState('')
  const [counterData, setCounterData] = useState('')
  const [id, setID] = useState("")
  const dispatch = useDispatch();
  const [os, setOS] = useState("");
  const [upiAlert, setUpiAlert] = useState(false);
  const [upiAlertDesc, setUpiAlertDesc] = useState("");
  const [authModeStatus, setAuthModeStatus] = useState([]);


  const [show, setShow] = useState(false);
  const [showUPIModal, setShowUPIModal] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState()
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);
  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };
    handleResize();
    setOS(getOS());
    window.addEventListener('resize', handleResize);

    getAuthModeStatus()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // function get isActive status of all authentication mode
  const getAuthModeStatus = async() =>{
    const authModeStatus = await apiCall
    .post(apiConstants?.findData, {
      // whereQuery:{is_active: true},
      tablename: tableNamePaymentConstants?.AUTH_MODE_STATUS,
      attributes: ['auth_mode', 'is_active'],
    })
    .then(async (res) => {
      console.log("------------------->getAuthModeStatus", res.data)
      setAuthModeStatus(res.data?.response || [])
    }).catch((err) => {
      console.log("--------------ERROR--------------")
      setAuthModeStatus([])
    })
  }

  const [aadharShow, setAadharShow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const aadharAlertShow = () => setAadharShow(true);
  const aadharAlertClose = () => setAadharShow(false);

  const [cookieWarning, setcookieWarning] = useState(false)
  const handleClose = () => { setShowUPIModal(false) };

  const { key } = useParams();
  const validSchemaModal = Yup.object().shape({
    vpa: Yup.string()
      .required('UPI ID is required')
      .test('is-not-empty', 'UPI ID cannot be empty', (value) => value.trim() !== '')
      .matches(/^[\w.-]+@[\w.-]+$/, 'Invalid UPI ID'),
  });
  const validSchema = object({
    account_holder_name:
      EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
      decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE &&
      string().required(YupMessages?.AccountHolderNameRequired),
    account_type:
      EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
      decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE &&
      string().required(YupMessages?.AccountTypeRequired),
    account_number:
      (decodedVal?.case_type === EnachStatusCode.SWAP_CASE_TYPE ||
        (EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
          decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE)) &&
      string().required(YupMessages?.AccountNumberRequired),
    ifsc_code:
      (decodedVal?.case_type === EnachStatusCode?.SWAP_CASE_TYPE ||
        (EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
          decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE)) &&
      string().required(YupMessages?.IFSCCodeIsRequired),
    bank_name:
      (decodedVal?.case_type === EnachStatusCode?.SWAP_CASE_TYPE ||
        (EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
          decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE)) &&
      string().required(YupMessages?.BankNameIsRequired),
    mobile_number:
      EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
      decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE &&
      string().required(YupMessages?.MobileNumberRequired),
    email_id:
      EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
      decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE &&
      string().required(YupMessages?.EmailIdIsRequired),
  });

  const fetchBankList = async (companyCode) => {
    const params = {
      tablename: tableNamePaymentConstants?.SponsorBank,
      whereQuery: {
        company_code: companyCode,
        mandate_type: EnachStatusCode.MANDATE_TYPE,
      },
      attributes: bankListAttributes,
    };
    await apiCall
      .post(apiConstants?.findData, params)
      .then((response) => {
        if (response.status === 200) {
          let result = response?.data?.response.map((item) => {
            return {
              key: item?.bank_name,
              value: item?.bank_name,
              netbanking_val: item.bank_id,
              bank_code: item?.bank_code,
              debit_card_id_val: item.debit_card_emandate_id,
              nupay_debit_flag: item.nupay_debit_flag,
              nupay_netbanking_flag: item.nupay_netbanking_flag,
              nupay_bank_id: item.nupay_bank_id,
              amount_to_pay: item.amount_to_pay,
              aadhar_bank_code: item?.aadhar_bank_code,
              nupay_esign_bank_code: item?.nupay_esign_bank_code
            };
          });
          result.splice(0, 0, { key: "Please Select Bank Name", value: "" });
          setBankList([...result]);
        }
      })
      .catch((error) => {
        toast.error(error?.message || errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };
  const payViaUPI = async(params) => {
    setDownloadFlag(true);
    params.key = key;
    const whereQuery = { bitly_sms_status: key }
    const radioBody = { vendor_type: EnachVendorTypes?.ICICI_UPI,'auth_mode': radioVal, customer_transaction_flag: 1, enach_initiated_time: moment().format('YYYY-MM-DD HH:mm:ss'), mandate_maximum_amount : decodedVal?.upi_max_amount }
    await updateAuthMode(radioBody, whereQuery);
    apiCall.post(enachTechProcessConstants?.upiTransactionProcess, params).then((response) => {
      if (response.status === 200) {
        setDownloadFlag(false)
        if (response?.data?.status !== 200) //return toast.error(response?.data?.message, toastIds?.InternalServerError)
        {
          if (response?.data?.status === 400) {
            if(response?.data?.message === 'Virtual address not present'){
              setUpiAlertDesc("Invalid UPI ID");
              setUpiAlert(true);
              return
            }else if (response?.data?.message === 'Duplicate Merchant Transaction ID Please Register New Mandate'){
              setUpiAlertDesc("Already Register for UPI .Please Register New Mandate");
              setUpiAlert(true);
              return
            }
            const params = { key: key }
            apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
              .then((response) => {
                if (response?.status === 200) {
                  if (response?.data?.status === 200) {
                    return window.location.href = response?.data?.URL
                  }
                }
              }).catch((error) => {
                toast.error(error?.message || errMessage?.InternalServerError, {
                  toastId: toastIds?.InternalServerError,
                });
              })
          } else {
            return toast.error(response?.data?.message, toastIds?.InternalServerError)
          }
        }
        else {
          const ws = new WebSocket(`${enachTechProcessConstants?.webSocketForWebhook}`);
          ws.onopen = () => {
            console.log('WebSocket connected');
          };
          dispatch(webSocketData(ws))
          switch (response?.data?.message) {
            case 'UPI_INTENT':
              window.location.href = response?.data?.response
              // let intentTimeout = setTimeout(() => {
              //   window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`;
              // }, 30000);

              // ws.onopen = () => {
              //   console.log('WebSocket connected---------------- > intent');
              // };
            
              // ws.onmessage = (event) => {
              //   const data = JSON.parse(event.data);
              //   if (data.payload === 200 && data.key === key) {
              //     clearTimeout(intentTimeout);
              //     const params = { key: key };
              //     apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
              //       .then((response) => {
              //         if (response?.status === 200) {
              //           if (response?.data?.status === 200) {
              //             window.location.href = response?.data?.URL;
              //           }
              //         }
              //       })
              //       .catch((error) => {
              //         toast.error(error?.message || errMessage?.InternalServerError, {
              //           toastId: toastIds?.InternalServerError,
              //         });
              //       });
              //   }
              // };
              return setTimeout(() => {
                ws.onopen = () => {
                  console.log('WebSocket connected---------------- >');
                };
                ws.onmessage = (event) => {
                  const data = JSON.parse(event.data);
                  if (data.payload === 200 && data.key === key) {
                    const params = { key: key }
                    apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
                      .then((response) => {
                        if (response?.status === 200) {
                          if (response?.data?.status === 200) {
                            return window.location.href = response?.data?.URL
                          }
                        }
                      }).catch((error) => {
                        toast.error(error?.message || errMessage?.InternalServerError, {
                          toastId: toastIds?.InternalServerError,
                        });
                      })
                  }
                };
                window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`
              }, 15000)
            // case 'UPI_INTENT':
            //   window.location.href = response?.data?.response;

            //   // Assign WebSocket event handlers immediately
            //   ws.onopen = () => {
            //     console.log('WebSocket connected---------------- >');
            //   };

            //   ws.onmessage = (event) => {
            //     const data = JSON.parse(event.data);
            //     if (data.payload === 200 && data.key === key) {
            //       clearTimeout(timeoutId);
            //       const params = { key: key };
            //       apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
            //         .then((response) => {
            //           if (response?.status === 200) {
            //             if (response?.data?.status === 200) {
            //               return window.location.href = response?.data?.URL;
            //             }
            //           }
            //         }).catch((error) => {
            //           toast.error(error?.message || errMessage?.InternalServerError, {
            //             toastId: toastIds?.InternalServerError,
            //           });
            //         });
            //     }
            //   };
            //   const timeoutId = setTimeout(() => {
            //     window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`;
            //   }, 15000);

            case 'qr_code':
              setQrCodeImage(response?.data?.response)
              // let qrTimeout = setTimeout(() => {
              //   window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`;
              // }, 30000);
              // ws.onopen = () => {
              //   console.log('WebSocket connected---------------- >');
              // };
            
              // ws.onmessage = (event) => {
              //   const data = JSON.parse(event.data);
              //   if (data.payload === 200 && data.key === key) {
              //     // Clear the timeout since we've received a response
              //     clearTimeout(qrTimeout);
            
              //     const params = { key: key };
              //     apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
              //       .then((response) => {
              //         if (response?.status === 200) {
              //           if (response?.data?.status === 200) {
              //             window.location.href = response?.data?.URL;
              //           }
              //         }
              //       })
              //       .catch((error) => {
              //         toast.error(error?.message || errMessage?.InternalServerError, {
              //           toastId: toastIds?.InternalServerError,
              //         });
              //       });
              //   }
              // };
              return setTimeout(() => {
                ws.onopen = () => {
                  console.log('WebSocket connected---------------- >');
                };

                ws.onmessage = (event) => {
                  const data = JSON.parse(event.data);
                  if (data.payload === 200 && data.key === key) {
                    const params = { key: key }
                    apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
                      .then((response) => {
                        if (response?.status === 200) {
                          if (response?.data?.status === 200) {
                            return window.location.href = response?.data?.URL
                          }
                        }
                      }).catch((error) => {
                        toast.error(error?.message || errMessage?.InternalServerError, {
                          toastId: toastIds?.InternalServerError,
                        });
                      })
                  }
                };
                window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`
              }, 30000)
            // case 'qr_code':
            //   setQrCodeImage(response?.data?.response);
            //   ws.onopen = () => {
            //     console.log('WebSocket connected---------------- > on qr page');
            //   };

            //   ws.onmessage = (event) => {
            //     const data = JSON.parse(event.data);
            //     if (data.payload === 200 && data.key === key) {
                  
            //       const params = { key: key };
            //       apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
            //         .then((response) => {
            //           if (response?.status === 200) {
            //             if (response?.data?.status === 200) {
            //               return window.location.href = response?.data?.URL;
            //             }
            //           }
            //         }).catch((error) => {
            //           toast.error(error?.message || errMessage?.InternalServerError, {
            //             toastId: toastIds?.InternalServerError,
            //           });
            //         });
            //         clearTimeout(timeoutIdQR);
            //     }
            //   };
            //   const timeoutIdQR = setTimeout(() => {
            //     window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`;
            //   }, 30000);
            default:
              // console.log("------------- upi---------");
              // ws.onopen = () => {
              //   console.log('WebSocket connected---------------- >');
              // };

              // ws.onmessage = (event) => {
              //   const data = JSON.parse(event.data);
              //   if (data.payload === 200 && data.key === key) {
              //     const params = { key: key }
              //     apiCall.post(enachTechProcessConstants?.upiResponseStatus, params)
              //       .then((response) => {
              //         if (response?.status === 200) {
              //           if (response?.data?.status === 200) {
              //             return window.location.href = response?.data?.URL
              //           }
              //         }
              //       }).catch((error) => {
              //         toast.error(error?.message || errMessage?.InternalServerError, {
              //           toastId: toastIds?.InternalServerError,
              //         });
              //       })
              //   }
              // };
              return window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`
          }
        }
      }
    }).catch((error) => {
      console.log('Error', error)
      setDownloadFlag(false)
      toast.error(
        errMessage?.InternalServerError,
        toastIds?.InternalServerError
      );
    })
  }

  const handleBankListChange = (val) => {
    const bankDetails = bankList.filter((item) => item?.value === val);
    const { netbanking_val, bank_code, debit_card_id_val, nupay_bank_id, nupay_debit_flag, nupay_netbanking_flag, amount_to_pay, aadhar_bank_code,nupay_esign_bank_code } = { ...bankDetails[0] };
    decodedVal.debit_card_emandate_id = debit_card_id_val;
    decodedVal.bank_code = bank_code;
    decodedVal.nupay_netbanking_flag = nupay_netbanking_flag;
    decodedVal.nupay_debit_flag = nupay_debit_flag;
    decodedVal.bank_id = decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.NUPAY ? nupay_bank_id : netbanking_val
    decodedVal.amount_to_pay = amount_to_pay
    decodedVal.aadhar_bank_code = aadhar_bank_code
    decodedVal.nupay_esign_bank_code = nupay_esign_bank_code
  };


  const checkLinkValidity = () => {
    alert('Your link has been expired as you have already completed your ENACH Journey.');
    window.open("about:blank", "_self");

  };

  const handleSuccessCase = async (source_system_unique_number) => {
    const response = await apiCall.post(apiConstants?.findData, {
      tablename: tableNamePaymentConstants?.ManualMandate,
      whereQuery: {
        source_system_unique_number: source_system_unique_number,
        mandate_status: EnachStatusCode.SUCCESS_STATUS_CODE,
      },
      attributes: mandateTypeAttributes,
      order: DtRepresentationTableOrder,
    });

    if (response.status === 200 && response?.data?.status === 200) return window.location.href = `${enachTechProcessConstants?.responseStatus}/${response?.data?.response[0].bitly_sms_status}`;

    return response
  };

  const processRegistration = async (source_system, enach_mandate_id, vendor_type, nupay_unique_url, web_top, customer_transaction_counter, currentTime) => {
    try {
      const response = await apiCall.post(enachTechProcessConstants?.decodeToken, {
        id: enach_mandate_id,
      });
      if (response.status === 200) {
        if (response?.data?.status === 200) {
          if (customer_transaction_counter > 0 && currentTime < 16) {
            Time.push(currentTime - 15)

            setcookieWarning(true);

          }
          if ((EnachStatusCode.VENDOR_TYPES.includes(vendor_type)) && (web_top != null || web_top != undefined || web_top != '')) {
            const getcounter = await fetchCounter(web_top)
            const maxAmount = parseInt(response?.data?.message?.max_amount)
            response.data.message.transaction_counter = getcounter
            response.data.message.max_amount = (getcounter >= 15 ? response?.data?.message?.max_amount : maxAmount - getcounter)
          } else {
            response.data.message.transaction_counter = 0
          }
          if (vendor_type === EnachStatusCode.VENDOR_TYPE.NUPAY && nupay_unique_url) {
            setNupayFlag(true);
            response.data.message.nupay_unique_url = nupay_unique_url
          }
          setDownloadFlag(false)
          setAuth(true);
          if (response?.data?.message.bank_upi_flag === StatusCodeFlags.TRUE && response?.data?.message.upi_option === StatusCodeFlags.TRUE) {
            setRadioVal("UPI");
          }
          setDecodedVal(response?.data?.message);
          if ((EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system)) || (response?.data?.message?.case_type === EnachStatusCode.SWAP_CASE_TYPE)) {
            await fetchBankList(response?.data?.message?.company_code);
          }
        } else if (response?.data?.status === 400) {
          checkLinkValidity()
        }
      }
    } catch (error) {
      console.log('Error ::', error)
      throw error
    }
  };

  const
    fetchEnachRecords = async (keyValue) => {
      setDownloadFlag(true);
      try {
        const params = {
          tablename: tableNamePaymentConstants?.ManualMandate,
          attributes: manualMandateAttributes,
          whereQuery: { bitly_sms_status: keyValue },
        };

        const enachRecords = await apiCall.post(apiConstants?.findData, params);
        console.log("--------> enachRecords",enachRecords);
        if (enachRecords?.status === 200) {
          const {
            mandate_status,
            enach_mandate_id,
            mandate_type,
            source_system,
            vendor_type,
            source_system_unique_number,
            nupay_unique_url,
            web_top,
            id,
            enach_initiated_time,
            customer_transaction_counter
          } = enachRecords?.data?.response[0];

          setSourceSystem(source_system);
          setWebTop(web_top)
          setID(id)

          if (enachRecords?.data?.status !== 200) {
            setDownloadFlag(false)
            return (window.location.href = apiConstants?.badGatewayError);
          }
          const targetTimestamp = moment(enach_initiated_time, 'YYYY-MM-DD HH:mm:ss');
          const currentTime = moment()

          switch (true) {
            case enach_mandate_id === "" && EnachStatusCode.MANDATE_STATUS_CODES.includes(mandate_status):
              setDownloadFlag(false);
              checkLinkValidity();
              break;

            case mandate_status === EnachStatusCode.SUCCESS_STATUS_CODE:
              setDownloadFlag(false);
              return window.location.href = `${enachTechProcessConstants?.responseStatus}/${key}`

            case mandate_type === EnachStatusCode.MANDATE_TYPE && ['SFDC', 'SFDC_CS'].includes(source_system.trim().toUpperCase()):
              await handleSuccessCase(source_system_unique_number);

            default:
              await processRegistration(source_system, enach_mandate_id, vendor_type, nupay_unique_url, web_top, customer_transaction_counter, currentTime.diff(targetTimestamp, 'minutes'));
          }
        }
      } catch (error) {
        setDownloadFlag(false)
        console.error('Error fetching data:', error);
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      }
    };

  const updateAuthMode = async (body, whereCondition) => {
    const response = await apiCall
      .post(apiConstants?.updateUser, {
        tablename: tableNamePaymentConstants?.ManualMandate,
        whereQuery: whereCondition,
        body: body,
      })
    console.log('Updated');
    if (response) return response

  };

  const updateProcessCounter = async () => {
    let { counter } = counterData
    if (counter <= 15) {
      let body = {
        'counter': counter + 1,
      }
      const response = await apiCall
        .post(apiConstants?.updateUser, {
          tablename: tableNamePaymentConstants?.ManualMandate,
          whereQuery: { web_top: webTop },
          // whereQuery: {web_top: webTop,id:id},
          body: body,
          "order": [['id', 'DESC']],
        })
      if (response) return response
    } else {
      return true
    }
  }

  const fetchCounter = async (web_top) => {
    const params = { web_top: web_top }
    const fetchCounter = await apiCall.post(`${enachTechProcessConstants.getProcessCounter}`, params)

    if (fetchCounter?.data?.status === 200) {
      return fetchCounter?.data?.response
    }
    return 0
  }
  const updateCustomerLogs = async (customerLog, value) => {
    const { txn_id, web_top, source_system } = value;
    const array = customerLog.map((item) => {
      return {
        txn_id: txn_id,
        user_id: source_system,
        web_top: web_top ? web_top : "",
        source_system: source_system,
        json_body: item?.customerLogs,
        description: item?.description,
      }
    })
    const response = await apiCall.post(enachApiConstants?.saveLogs, { array: array, txn_id: txn_id })
    return response
  };


  const updateSwapFields = async (values) => {

    const fetchBankCode = {
      "tablename": "sponsorBank",
      "attributes": ['bank_code'],
      "order": [['id', 'DESC']],
      "whereQuery": { mandate_type: EnachStatusCode.MANDATE_TYPE, bank_name: values.bank_name, company_code: values.company_code }
    }
    await apiCall.post(apiConstants?.findData, fetchBankCode).then(async (bankResponse) => {
      if (bankResponse?.data?.status === 200) {
        const fetchbankResponse = bankResponse?.data?.response[0]
        const fetchMicr = {
          "tablename": "micr",
          "order": [['id', 'DESC']],
          "attributes": ['micr_code'],
          "whereQuery": { ifsc_code: values.ifsc_code }
        }
        await apiCall.post(apiConstants?.findData, fetchMicr).then(async (micrResponse) => {
          const fetchmicrResponse = micrResponse?.data?.response[0]
          if (micrResponse?.data?.status === 200) {
            const updateParams = {
              account_number: values?.account_number,
              account_type: values?.account_type,
              ifsc_code: values?.ifsc_code,
              bank_name: values?.bank_name,
              micr_code: fetchmicrResponse?.micr_code,
              bank_code: fetchbankResponse?.bank_code
            }
            if (decodedVal.txn_id != null || decodedVal.txn_id != '' || decodedVal.txn_id != undefined) {
              await apiCall.post(apiConstants?.updateUser, {
                "tablename": "manualMandate",
                "whereQuery": { mandate_request_id: decodedVal.txn_id },
                "body": updateParams
              }).then((response) => {
                return true
              })
              await decodedVal.case_type === EnachStatusCode.SWAP_CASE_TYPE &&
                apiCall.post(apiConstants?.updateUser, {
                  "tablename": "manualMandateSwap",
                  "whereQuery": { mandate_request_id: decodedVal.txn_id },
                  "body": updateParams
                }).then((response) => {
                  return true
                })
            }
            else {
              console.log('Invalid Transaction ID')
            }

          }
        })
      }
    }).catch((error) => {
      toast.error('Internal Server Error', { toastId: 'swapError' })
    })

  }
  const findProduct = async () => {
    const fetchBankCode = {
      "tablename": "product",
      "attributes": ['emandate_amount', 'upi_emandate_amount', 'upi_flag'],
      "whereQuery": { product_code: "TW" }
    };
    try {
      const bankResponse = await apiCall.post(apiConstants?.findData, fetchBankCode);
      let response = bankResponse?.data?.response;
      return response;
    } catch (e) {
      return null;
    }
  };
  const createHashForSwap = async (values, auth_mode) => {

    const body_params = `L253089|${decodedVal?.txn_id}|${decodedVal?.amount_to_pay}|${values.account_number
      }|${decodedVal?.txn_id}|${values?.mobile_number}|${values?.email_id
      }|${decodedVal?.debit_start_date}|${decodedVal?.debit_end_date}|${decodedVal?.max_amount
      }|${decodedVal?.amount_type}|${decodedVal?.frequency}|${(decodedVal.card_number || '')}|${(decodedVal.expiry_month ||
        '')}|${(decodedVal.expiry_year || '')}|${(decodedVal.cvv_code || '')}|3624322694LWBXMC`
    console.log("::::::::", body_params)
    await apiCall.post(enachTechProcessConstants?.createHashValue, { body_params }).then(async (response) => {
      if (response?.status == 200) {
        decodedVal.token = response.data.response
        await redirectTechProcess(values, auth_mode)
      }
    }).catch((error) => {
      return toast.error(errMessage?.InternalServerError, {
        toastId: toastIds?.InternalServerError,
      });
    })

  }


  const proceedCase = async (values, key) => {
    console.log(key, "key");
    console.log("proceedCAse --- ", values);
    if (radioVal === "" || radioVal === undefined || radioVal === "")
      return toast.info(errMessage?.SelectAuthenticationMode, {
        toastId: toastIds?.SelectAuthenticationMode,
      });
    if (isChecked !== true)
      return toast.info(errMessage?.CannotProceedwithoutTermsAndConditions, {
        toastId: toastIds?.CannotProceedwithoutTermsAndConditions,
      });
    setDownloadFlag(true)
    // const productResponse = await findProduct();
    const mandateEndDate =  radioVal === "UPI" 
          ? moment(decodedVal?.debit_start_date,'DD-MM-YYYY').add(360,'months').format('DD-MM-YYYY') 
          :  moment(decodedVal?.debit_start_date,'DD-MM-YYYY').add(480,'months').subtract(1, 'day').format('DD-MM-YYYY');
    const emandateAmount = radioVal=="UPI" ? decodedVal?.upi_max_amount :  decodedVal?.emandate_max_amount ; 
    const whereQuery = { bitly_sms_status: key }
    const radioBody = { 'auth_mode': radioVal, customer_transaction_flag: 1, 
      enach_initiated_time: moment().format('YYYY-MM-DD HH:mm:ss'), 
      mandate_maximum_amount: emandateAmount,
      mandate_end_date:mandateEndDate }
      console.log("radioBody============= ",radioBody)
    await updateAuthMode(radioBody, whereQuery);
    if (decodedVal.case_type === EnachStatusCode.SWAP_CASE_TYPE || EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) && decodedVal.company_code === EnachStatusCode.TCFSL_COMPANY_CODE) {
      await updateSwapFields(values)
    }
    console.log('------------------> radioVal', radioVal);
    switch (radioVal) {
      case AuthModes.UPI:
        const emandateUPIAmount = decodedVal.upi_max_amount
        const body = { vendor_type: EnachVendorTypes?.ICICI_UPI, auth_mode: EnachVendorTypes?.UPI,mandate_maximum_amount: emandateUPIAmount,mandate_end_date:mandateEndDate  }
        await updateAuthMode(body, whereQuery)
        console.log("window location href");
      // return (window.location.href = `${enachTechProcessConstants?.upiTransactionPage}/${key}`);

      default:
        switch (decodedVal?.vendor_type) {
          case EnachStatusCode.VENDOR_TYPE.NUPAY:
            nupayFlag && (window.location.href = decodedVal.nupay_unique_url);
            const auth_type = radioVal === 'aadhar' ? 'Aadhaar' : radioVal;
            const bank_id = auth_type === 'Aadhaar' ? decodedVal?.nupay_esign_bank_code : decodedVal?.bank_id;
            const params = {
              txn_id: decodedVal.txn_id,
              company_code: decodedVal.company_code,
            };
            const nupayParams = {
              loan_no: decodedVal?.txn_id,
              seq_tp: EnachStatusCode.NUPAY_RECR_VALUE,
              frqcy: decodedVal?.frequency,
              frst_colltn_dt: decodedVal?.debit_start_date,
              fnl_colltn_dt: decodedVal?.debit_end_date,
              colltn_until_cncl:
                decodedVal?.debit_end_date == null
                  ? StatusCodeFlags.FALSE
                  : StatusCodeFlags.TRUE,
              colltn_amt: decodedVal?.max_amount,
              debit_type: StatusCodeFlags.FALSE,
              mobile_no: values?.mobile_number,
              tel_no: "",
              bank_id: bank_id,
              auth_type: auth_type,
              email: decodedVal?.email_id,
              account_holder_name: values?.account_holder_name,
              account_type:
                values?.account_type == (commonVariables?.SavingAccount || "10")
                  ? commonVariables?.Savings
                  : commonVariables?.Current,
              ifsc_code: values?.ifsc_code,
              bank_account_no: values?.account_number,
              bank_account_no_confirmation: values?.account_number,
              category_id: EnachStatusCode.NUPAY_CATEGORY_CODE,
            };
            const body = {
              nupayParams,
              params,
            };
            console.log(body, "body in nupay");
            await apiCall
              .post(enachTechProcessConstants?.redirectNupay, body)
              .then(async (response) => {
                if (response.status === 200) {
                  if (response?.data?.status === 400)
                    return toast.info(response?.data?.message, {
                      toastId: errMessage?.noDataFound,
                    });
                  if (response?.data?.status === 500)
                    return toast.error(errMessage?.InternalServerError, {
                      toastId: toastIds?.InternalServerError,
                    });
                    const emandateAmt =  decodedVal?.max_amount 
                  const body = { nu_unique_id: response?.data?.response?.data?.customer?.id, vendor_type: EnachVendorTypes?.NUPAY, customer_transaction_counter: (decodedVal?.transaction_counter + 1), mandate_maximum_amount : emandateAmt,mandate_end_date:mandateEndDate}
                  await updateAuthMode(body, whereQuery)
                  const nupayArray = [
                    {
                      customerLogs: JSON.stringify(response?.data?.response),
                      description: EnachStatusCode.CUSTOMER_INITIATED_TRANSACTION_NUPAY
                    }
                  ]
                  await updateCustomerLogs(nupayArray, decodedVal)
                  window.location.href = response?.data?.response?.data?.url;


                }
              }).catch((error) => {
                toast.error('Internal Server Error', { toastId: 'techProcessPageError' })
              }).finally(() => {
                setDownloadFlag(false)
              })

            break;
          default:
            await redirectTechProcess(values, radioVal);
        }
    }

  };

  const getHash = async (values) => {
    const body_params = `${decodedVal.merchant_id}|${decodedVal.txn_id}|${decodedVal.amount_to_pay || decodedVal.total_amt}|${values.account_number
      }|${decodedVal.txn_id}|${values.mobile_number}|${values.email_id
      }|${decodedVal?.debit_start_date}|${decodedVal?.debit_end_date}|${decodedVal?.max_amount
      }|${decodedVal?.amount_type}|${decodedVal?.frequency}|${(decodedVal.card_number || '')}|${(decodedVal.expiry_month ||
        '')}|${(decodedVal.expiry_year || '')}|${(decodedVal.cvv_code || '')}|${decodedVal?.salt}`


    const response = await apiCall.post(enachTechProcessConstants?.createHashValue, { body_params })
    try {
      if (response?.status == 200) {
        decodedVal.token = response.data.response

        return ({ body_params: body_params })
      }
    } catch (error) {

      return toast.error(errMessage?.InternalServerError, {
        toastId: toastIds?.InternalServerError,
      });
    }
  }

  const redirectTechProcess = async (values, authMode) => {
    const returnUrl = enachTechProcessConstants?.tpslResponseStatus;
    console.log(returnUrl, "returnUrl","\n values: " + JSON.stringify(values));
    const gethash = await getHash(values, authMode);
    console.log("gethash", gethash);
    const configJson = tpslConfig(returnUrl, values, decodedVal, authMode);
    if (configJson === null) return toast.error("Internal Server Error", { toastId: "techProcessPageError" });
    const array = [
      {
        customerLogs: gethash?.body_params,
        description: EnachStatusCode?.NON_ENCODED_VALUES
      },
      {
        customerLogs: decodedVal?.token,
        description: EnachStatusCode?.ENCODED_VALUES
      },
      {
        customerLogs: JSON.stringify(configJson),
        description: EnachStatusCode.CUSTOMER_INITIATED_TRANSACTION
      }
    ]
    await updateCustomerLogs(array, decodedVal);
    const whereQuery = { bitly_sms_status: key }
    const body = { vendor_type: EnachVendorTypes?.TPSL, customer_transaction_counter: (decodedVal?.transaction_counter + 1),account_number :decodedVal?.account_number }
    await updateAuthMode(body, whereQuery)
    setDownloadFlag(false)
    window.$.pnCheckout(configJson);
    if (configJson.features.enableNewWindowFlow) {
      window.pnCheckoutShared.openNewWindow();
    }
  }

  useEffect(() => {
    fetchEnachRecords(key);
  }, []);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // const productResponse = await findProduct();
        const emandateAmount = radioVal === "UPI"
          ? decodedVal?.upi_max_amount
          : decodedVal?.emandate_max_amount;
          console.log("--------------->>>",moment(decodedVal?.debit_start_date).add(480,'months').subtract(1, 'day').format('DD-MM-YYYY') ,decodedVal?.debit_end_date);
        const mandateEndDate =  radioVal === "UPI" 
          ? moment(decodedVal?.debit_start_date,'DD-MM-YYYY').add(360,'months').format('DD-MM-YYYY') 
          :  moment(decodedVal?.debit_start_date,'DD-MM-YYYY').add(480,'months').subtract(1, 'day').format('DD-MM-YYYY');
          console.log("--------------->,,,,,",mandateEndDate);
        setDecodedVal(prevState => ({
          ...prevState,
          max_amount: emandateAmount,
          debit_end_date : mandateEndDate,
        }));
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
  
    fetchProductData();
  }, [radioVal]);

  return (
    <>
      <ModalComponent
        className="modal-dialog modal-lg"
        btnTitle={"Ok"}
        show={cookieWarning}
        modalClose={() => setcookieWarning(false)}
        onHide={() => setcookieWarning(false)}
        title="Please Note"
        modalBody={`Dear Customer, we suggest that you re-try after ${Math.abs(Time[0])} minutes.`}
      ></ModalComponent>
      <div>
        {downloadFlag && (
          <div className="className='row justify-content-center d-flex loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <Formik
          initialValues={{
            loan_number: decodedVal?.loan_no,
            los_id: decodedVal?.los_id,
            mandate_request_id: decodedVal?.txn_id,
            account_holder_name: decodedVal?.account_holder_name,
            account_type: decodedVal?.account_type,
            account_number:
              decodedVal?.case_type === EnachStatusCode?.SWAP_CASE_TYPE
                ? ""
                : decodedVal?.account_number,
            ifsc_code:
              decodedVal?.case_type === EnachStatusCode?.SWAP_CASE_TYPE
                ? ""
                : decodedVal?.ifsc_code,
            bank_name:
              decodedVal?.case_type === EnachStatusCode?.SWAP_CASE_TYPE
                ? ""
                : decodedVal?.bank_name,
            mobile_number: decodedVal?.consumer_mobile_number,
            email_id: decodedVal?.email_id,
            mandate_maximum_amount: decodedVal?.max_amount,
            purpose_of_mandate: EnachStatusCode?.LOAN_INSTALLMENT,
            mandate_start_date: decodedVal?.debit_start_date,
            mandate_end_date:
              decodedVal?.unti_cancelled === true
                ? ""
                : decodedVal?.debit_end_date,
            corporate:
              decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE
                ? "Tata Capital Ltd"
                : "Tata Capital Housing Finance Ltd",
            utility_code:
              decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE
                ? "HDFC00065000003219"
                : "HDFC00064000003218",
            reference_number: decodedVal?.source_system_unique_number,
            frequency: decodedVal?.frequency,
          }}
          enableReinitialize={true}
          validationSchema={validSchema}
          onSubmit={(values) => { (radioVal == "UPI" && isChecked && isMobile && os === 'Linux') ? payViaUPI({ upi_method: 'UPI_INTENT', key: key, os: os }) : (radioVal == "UPI" && isChecked) ? setShowUPIModal(!showUPIModal) : proceedCase(values, key) }}
        >
          {(props) => {
            const { values } = props;
            return (
              <Form>
                {auth === true &&
                  <div className="container-fluid bg">
                    <div>
                      <nav className="main_navbar d-flex justify-content-center justify-content-lg-end ">
                        <h5 className="mr-2 mt-1 ">
                          <i className="fa fa-envelope"></i>{" "}
                          <label className="mr-2 text-right">
                            {decodedVal?.company_code === EnachStatusCode?.TCFSL_COMPANY_CODE ? 'customercare@tatacapital.com' : 'customercare.housing@tatacapital.com'}
                          </label>
                          <i className="fa fa-phone "></i>{" "}
                          <label>1860 267 6060</label>
                        </h5>
                      </nav>
                      <nav className="mynavbar navbar">
                        <div className="container d-flex justify-content-center justify-content-lg-between">
                          <img className="logo" src={logo} alt="logo" />
                          <img
                            className="secondimg"
                            src={logoImage}
                            alt="logo"
                          ></img>
                        </div>
                      </nav>
                    </div>

                    <div className="container-fluid">
                      <div className="container row justify-content-center d-flex mx-auto ">
                        <div className="row ps-5 pt-3 pb-4"></div>
                        <div className=" col-md-25 containerstyle ">
                          <div className="row">
                            {decodedVal.case_type ===
                              EnachStatusCode?.SWAP_CASE_TYPE ? (
                              <label className="d-flex justify-content-center pt-4">
                                REGISTER YOUR NEW BANK ACCOUNT
                              </label>
                            ) : (
                              <label className="d-flex justify-content-center pt-4">
                                REGISTER YOUR EMANDATE
                              </label>
                            )}
                            <div className="">
                              <hr></hr>
                            </div>
                          </div>
                          {decodedVal.case_type ===
                            EnachStatusCode.SWAP_CASE_TYPE && (
                              <div className="row justify-content-center d-flex pt-6 mb-2">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                  <div className="field_name">
                                    <FormLabel
                                      value="Loan Number"
                                      className="required"
                                    />
                                  </div>
                                  <Field
                                    type="text"
                                    className="form-control form-control-lg pt-1 w-100 h-25"
                                    name="loan_number"
                                    disabled={true}
                                    id="loan_number"
                                    placeholder="Enter Loan Number"
                                  ></Field>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12">
                                  <div className="field_name">
                                    <FormLabel
                                      value="LOS ID"
                                      className="required"
                                    />
                                  </div>
                                  <Field
                                    type="text"
                                    className="form-control form-control-lg pt-1 w-100 h-25"
                                    name="los_id"
                                    disabled={true}
                                    id="los_id"
                                    placeholder="Enter Los ID"
                                  ></Field>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12">
                                  <div className="field_name">
                                    <FormLabel
                                      value="Emandate Request ID"
                                      className="required"
                                    />
                                  </div>
                                  <Field
                                    type="text"
                                    className="form-control form-control-lg pt-1 w-100 h-25"
                                    name="mandate_request_id"
                                    disabled={true}
                                    id="mandate_request_id"
                                    placeholder="Enter Mandate Request ID"
                                  ></Field>
                                </div>
                              </div>
                            )}
                          <div className="row justify-content-center d-flex  pt-6">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="field_name">
                                <FormLabel
                                  value="Account Holder Name"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={
                                  EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(
                                    source_system
                                  ) &&
                                    decodedVal.company_code ===
                                    EnachStatusCode.TCFSL_COMPANY_CODE
                                    ? false
                                    : true
                                }
                                name="account_holder_name"
                                id="account_holder_name"
                                placeholder="Enter Holder Name"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="account_holder_name" />
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-sm-12">
                              <div className="field_name">
                                <FormLabel
                                  value="Account Type"
                                  className="required"
                                />
                              </div>

                              {decodedVal.case_type ===
                                EnachStatusCode.SWAP_CASE_TYPE ||
                                (EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(
                                  source_system
                                ) &&
                                  decodedVal.company_code ===
                                  EnachStatusCode.TCFSL_COMPANY_CODE) ? (
                                <FormikSelect
                                  label="select Account Type"
                                  name="account_type"
                                  className="form-group form-control-lg pt-1  w-100 h-25"
                                  id={"accountType"}
                                  options={accountType}
                                ></FormikSelect>
                              ) : (
                                <Field
                                  type="text"
                                  className="form-control form-control-lg pt-1 w-100 h-25"
                                  disabled={true}
                                  name="account_type"
                                  id="account_type"
                                  placeholder="Enter Account Type"
                                ></Field>
                              )}
                              <div className="errorMessage">
                                <ErrorMessage name="account_type" />
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-12 col-lg-2">
                              <div className="field_name">
                                <FormLabel
                                  value="Account Number"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={
                                  decodedVal.case_type ===
                                    EnachStatusCode.SWAP_CASE_TYPE ||
                                    EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
                                    decodedVal.company_code ===
                                    EnachStatusCode.TCFSL_COMPANY_CODE
                                    ? false
                                    : true
                                }
                                name="account_number"
                                id="account_number"
                                placeholder="Enter Account Number"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="account_number" />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-12">
                              <div className="field_name">
                                <FormLabel
                                  value="IFSC Code"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={
                                  decodedVal.case_type ===
                                    EnachStatusCode.SWAP_CASE_TYPE ||
                                    EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(source_system) &&
                                    decodedVal.company_code ===
                                    EnachStatusCode.TCFSL_COMPANY_CODE
                                    ? false
                                    : true
                                }
                                name="ifsc_code"
                                id="ifsc_code"
                                placeholder="Enter IFSC Code"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="ifsc_code" />
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center d-flex pt-2 ">
                            <div className="col-md-4 col-lg-4">
                              <div className="field_name">
                                <FormLabel
                                  value="Bank Name"
                                  className="required"
                                />
                              </div>
                              {decodedVal.case_type ===
                                EnachStatusCode.SWAP_CASE_TYPE ||
                                (EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(
                                  source_system
                                ) &&
                                  decodedVal.company_code ===
                                  EnachStatusCode.TCFSL_COMPANY_CODE) ? (
                                <FormikSelect
                                  label="Select Bank Name"
                                  className="form-group form-control-lg pt-1 w-100 h-25"
                                  name="bank_name"
                                  id={"bank_name"}
                                  options={bankList}
                                  handleChange={handleBankListChange(
                                    values?.bank_name
                                  )}
                                />
                              ) : (
                                <Field
                                  type="text"
                                  className="form-control form-control-lg pt-1 w-100 h-25"
                                  disabled={true}
                                  name="bank_name"
                                  id="bank_name"
                                  placeholder="Enter Bank Name"
                                ></Field>
                              )}
                              <div className="errorMessage">
                                <ErrorMessage name="bank_name" />
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                              <div className="field_name">
                                <FormLabel
                                  value="Mobile Number"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={
                                  EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(
                                    source_system
                                  ) &&
                                    decodedVal.company_code ===
                                    EnachStatusCode.TCFSL_COMPANY_CODE
                                    ? false
                                    : true
                                }
                                name="mobile_number"
                                id="mobile_number"
                                placeholder="Enter Mobile Number"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="mobile_number" />
                              </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                              <div className="field_name">
                                <FormLabel
                                  value="Email Id"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={
                                  EnachStatusCode.ENABLE_SOURCE_SYSTEMS.includes(
                                    source_system
                                  ) &&
                                    decodedVal.company_code ===
                                    EnachStatusCode.TCFSL_COMPANY_CODE
                                    ? false
                                    : true
                                }
                                name="email_id"
                                id="email_id"
                                placeholder="Enter Email Id"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="email_id" />
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center d-flex pt-2">
                            <div className="col-md-6 col-lg-3">
                              <label>Mandate Maximum Amount</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="mandate_maximum_amount"
                                id="mandate_maximum_amount"
                                placeholder="Enter Maximum Amount"
                              />
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <label>Frequency</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="frequency"
                                id="frequency"
                                placeholder="Enter Frequency"
                              />
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label>Purpose Of Mandate</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="purpose_of_mandate"
                                id="purpose_of_mandate"
                              />
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label>Type Of Amount</label>
                              <div className="form-check checkboxlabel">
                                <input
                                  className="form-check-input"
                                  checked={true}
                                  disabled
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label className={"form-check-label"} htmlFor="flexCheckDefault">
                                  Max Amount
                                </label>
                              </div>
                              <div className="form-check checkboxlabel">
                                <input
                                  className="form-check-input"
                                  disabled
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Fixed Amount
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center d-flex pt-2 ">
                            <div className="col-md-3 col-md-6 col-lg-4">
                              <label>Start Date</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="mandate_start_date"
                                id="mandate_start_date"
                                placeholder="Enter Mandate Start Date"
                              ></Field>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label>
                                Expiry Date{" "}
                                <span>&#x2768;DD-MM-YYYY&#x2769;</span>
                              </label>
                              <div style={{ position: "relative" }}>
                                <Field
                                  type="text"
                                  className="form-control form-control-lg pt-1 w-100 h-25"
                                  disabled={true}
                                  name="mandate_end_date"
                                  id="mandate_end_date"
                                ></Field>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-4">
                              <label>Corporate Name</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="corporate"
                                id="corporate"
                                placeholder="Enter Corporate Name"
                              ></Field>
                            </div>
                          </div>

                          <div className="row justify-content-center d-flex pt-2 ">
                            <div className="col-md-6 col-lg-2 col-sm-12">
                              <label>Utility Code</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="utility_code"
                                id="utility_code"
                                placeholder="Enter Utility Code"
                              ></Field>
                            </div>

                            <div className="col-md-6 col-lg-2 col-sm-12">
                              <label>Reference No</label>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                disabled={true}
                                name="reference_number"
                                id="reference_number"
                              ></Field>
                            </div>

                            <div className="col-lg-7 col-sm-12 col-md-12">
                              <label>Select Authentication Mode</label>
                              <div className="payment-options d-flex gap-2 flex-column flex-sm-row">

                                {
                                  isAuthModeActive(authModeStatus, 'UPI') ? 
                                  <>
                                  {decodedVal.bank_upi_flag === StatusCodeFlags.TRUE && decodedVal.upi_option === StatusCodeFlags.TRUE ?

                                    <div className="radio-box">
                                    <input
                                      type="radio"
                                      id="upiRadio"
                                      name="paymentOption"
                                      value="upi"
                                      checked={radioVal === "UPI"}
                                      onChange={(e) =>
                                        setRadioVal("UPI")
                                      }
                                    />

                                    <label htmlFor="upiRadio">UPI</label>

                                  </div> : ''}
                                        </>: ''
                                  }

                                {
                                  isAuthModeActive(authModeStatus, 'NetBanking') ? 
                                  <>
                                  {((decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.NUPAY && decodedVal.nupay_netbanking_flag && !decodedVal.upi_bank) || (decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.TPSL && decodedVal.bank_code && !decodedVal.upi_bank) || (decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.ICICI && !decodedVal.upi_bank)) ?

                                    <div className="radio-box">
                                      <input
                                        type="radio"
                                        id="netBankingRadio"
                                        name="paymentOption"
                                        value="netBanking"
                                        checked={radioVal === "NetBanking"}
                                        onChange={(e) =>
                                          setRadioVal("NetBanking")
                                        }
                                      />

                                      <label htmlFor="netBankingRadio">Net Banking</label>

                                    </div> : '' }
                                    </> : ''
                                }


                                {
                                  isAuthModeActive(authModeStatus, 'DebitCard') ?
                                  <>
                                  {((decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.NUPAY && decodedVal.nupay_debit_flag && !decodedVal.upi_bank) || (decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.TPSL && decodedVal.debit_card_emandate_id && !decodedVal.upi_bank) || (decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.ICICI && !decodedVal.upi_bank)) ?

                                    <div className="radio-box">
                                      <input
                                        type="radio"
                                        id="debitCardRadio"
                                        name="paymentOption"
                                        value="debitCard"
                                        checked={radioVal === "DebitCard"}
                                        onChange={(e) =>
                                          setRadioVal("DebitCard")
                                        }


                                      />

                                      <label htmlFor="debitCardRadio">Debit Card</label>

                                    </div> : '' }
                                    </> : ''
                                }
                                {
                                  isAuthModeActive(authModeStatus, 'aadhar') ? 
                                  <>
                                  {((decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.TPSL && decodedVal.aadhar_bank_code) || (decodedVal.vendor_type === EnachStatusCode.VENDOR_TYPE.ICICI && !decodedVal.upi_bank) || (decodedVal.vendor_type ===EnachStatusCode.VENDOR_TYPE.NUPAY && EnachStatusCode.NUPAY_ESIGN_BANK_ID.includes(decodedVal.nupay_esign_bank_code))) ?

                                    <div className="radio-box">
                                      <input
                                        type="radio"
                                        id="aadharRadio"
                                        name="paymentOption"
                                        value="aadhar"
                                        checked={radioVal === "aadhar"}
                                        onChange={(e) =>
                                          setRadioVal("aadhar")
                                        }
                                        onClick={aadharAlertShow}
                                      />
                                      <ModalComponent className="modal-dialog modal-lg" btnTitle={'Ok'} show={aadharShow} modalClose={aadharAlertClose} onHide={aadharAlertClose} title='Please Note'
                                        modalBody={aadharConditions?.params}>
                                      </ModalComponent>
                                      <label htmlFor="aadharRadio">Aadhar</label>

                                    </div> : ''}
                                  </> : ''
                                }
                              </div>
                            </div>
                            
                            
                          </div>
                          <div className="row d-flex">  
                          <div className="col-sm-12 col-md-12 col-lg-7 check-margin check-margin-custom mb-3">
                              <div class="form-check-custom ms-3 pt-2 mb-2">
                                <input
                                  class="form-check-input "
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={(event) =>
                                    setisChecked(event.target.checked)
                                  }
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="d-block d-md-inline termstyle note text-wrap mb-2 ml-2"
                                  for="flexCheckDefault"
                                >
                                  By Clicking Submit, I agree to Tata Capital's{" "}
                                  <a href="#" onClick={modalShow} className="link-primary">
                                    terms and conditions
                                  </a>
                                  <ModalComponent className="modal-dialog modal-lg" show={show} btnTitle={'Cancel'} modalClose={modalClose} onHide={modalClose} title='Terms & Conditions'
                                    modalBody={termsAndConditions?.params}>
                                  </ModalComponent>
                                </label>
                              </div>
                              </div>
                            </div>

                        </div>
                        <div className="d-flex gap-3">
                          {
                            decodedVal.case_type === EnachStatusCode.SWAP_CASE_TYPE ?
                             ( <label className="note mt-2 ">
                                Note: You are in the process of registration of E-Mandate for your new bank account.
                                In case of any discrepancy in the above-mentioned fields, you may opt to cancel this
                                transaction. Click on ‘Proceed’ if the information in the above-mentioned fields are
                                correct.
                              </label>)

                              :  isMobile && os!=="ios" && radioVal === 'UPI'? 
                              <label className="note mt-2 ">
                              <ul>
                                <li>Mandate registration will not be successful if the bank account used for mandate registration is different from the bank account provided during the Loan Application.</li>
                                <li>Rs 1 will be debited from your account during mandate registration and the same will be credited back within 3 working days.</li>
                              </ul>
                              </label>  : 
                              !isMobile && radioVal ==='UPI' ? 
                              <label className="note mt-2 ">
                             
                              </label> :""
                          }
                        </div>

                        <div className="d-flex justify-content-center pt-2 mt-2 mb-4">
                          <button
                            id="ok"
                            type="submit"
                            className="btn btn-primary rounded"
                            onClick={() => { }}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                    <nav className="footer navbar d-flex justify-content-center">
                      <label>Copyright © 2020 Tata Capital</label>
                    </nav>
                    {isMobile && os=="ios" &&  <div className={"mb-5"}></div>}
                  </div>

                }
              </Form>
            );
          }}
        </Formik>
        {(showUPIModal) && (
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showUPIModal}
            onHide={handleClose}
            size="lg"
          >
            <Modal.Header className="blue-bg text-center">
              <div className="container d-flex justify-content-between justify-content-lg-between">
                <img className="logo" src={logo} alt="logo" />
                <img className="secondimg" src={autoPay} alt="logo" />
              </div>
            </Modal.Header>
            <div>
              {downloadFlag && (
                <div className="className='row justify-content-center d-flex loaderDiv">
                  <SystemXLoader />
                </div>
              )}
              <div className="container d-flex justify-content-center">
                <img className="logo" src={upiLogo} alt="logo" />
              </div>
              {upiAlert && (
                <Alert variant="danger" onClose={() => setUpiAlert(false)} dismissible>
                  {upiAlertDesc}
              </Alert>
              )}
              <div>
                <hr />
              </div>
              <Formik
                initialValues={{ vpa: '' }}
                enableReinitialize={true}
                validationSchema={validSchemaModal}
                onSubmit={(values) => {
                }}
              >
                {(formik) => (
                  <Form className="pr-3">
                    <div className="row position-relative">
                      {(isMobile && os == "ios") ? (
                        <>
                          <div className={`col-lg-6 col-md-6 col-sm-6`}>
                            <div className="d-flex justify-content-between position-relative mb-4">
                              <Image src={phonePay} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "phonePe", os: os };
                                  payViaUPI(params);
                                }} />
                              <Image src={googlePay} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "googlePay", os: os };
                                  payViaUPI(params);
                                }} />
                              {/* <Image src={amazonPay} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "amazonPay", os: os };
                                  payViaUPI(params);
                                }} /> */}
                              <Image src={bhimUPI} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "bhimUPI", os: os };
                                  payViaUPI(params);
                                }} />
                              <Image src={paytmLogo} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "paytm", os: os };
                                  payViaUPI(params);
                                }} />
                              {/* <Image src={CredApp} alt="" className="upiImg qr-borderImg img-fluid"
                                onClick={() => {
                                  const params = { upi_method: 'UPI_INTENT', psp_app: "CredApp", os: os };
                                  payViaUPI(params);
                                }} /> */}
                            </div>
                          </div>
                        </>) :

                        (<>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="pl-4 pt-4 pr-3">
                              <div className="field_name">
                                <div className="inputDiv mt-1">
                                  <p>
                                    Enter your UPI ID <span className="textLabelRed">*</span>
                                  </p>
                                </div>
                              </div>
                              <Field
                                type="text"
                                className="InputField form-control form-control-lg pt-1 w-80 h-25"
                                name="vpa"
                                id="vpa"
                                placeholder="UPI ID"
                              />
                              <div className="errorMessage">
                                <ErrorMessage name="vpa" />
                              </div>
                              <div className="d-flex gap-4 mt-3">
                                <ButtonReact
                                  className="customButtonGreen custom-button"
                                  onClick={() => {
                                    formik.setTouched({ vpa: true });
                                    if (formik.isValid && formik.values.vpa) {
                                      const params = { upi_method: 'UPI', vpa: formik.values.vpa };
                                      payViaUPI(params);
                                    } else {
                                      formik.validateForm('vpa');
                                    }
                                  }}
                                  disabled={!formik.isValid || selectedPaymentMethod === 'qr_code'}
                                >
                                  VERIFY & PAY
                                </ButtonReact>
                              </div>
                            </div>
                          </div>
                          <div className="or-wrapper-mobile">
                            <div className=""></div>
                            <span className="or-circle">OR</span>
                          </div>
                          <div className={`col-lg-6 col-md-6 col-sm-6 left-side`}>
                            <div className={`pl-2 pt-2 pr-2`}>
                              <div className="field_name">
                                <div className="inputDiv d-flex align-items-center justify-content-center">
                                  <p>
                                    Pay By QR Code <span className="textLabelRed">*</span>
                                  </p>
                                </div>
                              </div>
                              {qrCodeImage ? (
                                <div className="position-relative">
                                  <Image src={qrCodeImage} alt="" className="qr-codeImg qr-borderImg img-fluid" />
                                </div>
                              ) : (
                                <div className="position-relative">
                                  <Image src={qrCode} alt="" className="qr-code qr-border img-fluid" />
                                  <button
                                    type="button"
                                    className="btn btn-primary position-absolute top-50 start-50 translate-middle custom-button"
                                    onClick={() => {
                                      setSelectedPaymentMethod('qr_code');
                                      const params = { upi_method: 'qr_code' };
                                      payViaUPI(params);
                                    }}
                                  >
                                    Click to Scan QR Code
                                  </button>
                                </div>
                              )}
                              <span className="or-circle or-right">OR</span>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6 col-sm-6 blue-background">
                          <div className="d-flex gap-4 mt-3">
                            <button
                              type="button"
                              className="customButtonGreen"
                              onClick={() => {
                                setSelectedPaymentMethod('UPI_INTENT');
                                const params = { upi_method: 'UPI_INTENT',os:os };
                                payViaUPI(params);
                              }}
                            >
                              Pay By UPI App
                            </button>
                            <span className="or-circle or-right">OR</span>
                          </div>
                        </div> */}
                        </>
                        )}
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
            {(!isMobile && os != "ios") ? (<Modal.Footer className="blue-bg d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column w-100">
                <ul>
                  <li className="upi-note">Mandate registration will not be successful, if the bank account used for mandate registration is different than the bank account provided during Loan Application.</li>
                  <li className="upi-note">Kindly use the VPA/UPI ID linked to the account number provided during Loan Application</li>
                  <li className="upi-note">Rs 1 will be debited from your account during mandate registration and the same will be credited back within 3 working days.</li>
                </ul>
              {/* <ul>
                <li className="upi-note">Kindly use the VPA/UPI ID linked to the provided account number</li>
                <li className="upi-note">Please Note: For Account authentication purpose 1Rs will be debited from provided account and credited back within maximum 3 working days</li>
                </ul> */}
              </div>
              <Button onClick={handleClose} className="modalBtnCstm ml-auto">
                Close
              </Button>
            </Modal.Footer>) : (isMobile && os == "ios") ? 
            (<Modal.Footer className="blue-bg d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column w-100">
              <ul>
                <li className="upi-note-ios">Mandate registration will not be successful if the bank account used for mandate registration is different from the bank account provided during the Loan Application.</li>
                <li className="upi-note-ios">Rs 1 will be debited from your account during mandate registration and the same will be credited back within 3 working days.</li>
              </ul>
                </div>
            </Modal.Footer>):"" }

          </Modal>)}
      </div>
    </>
  );
};

export default Registermandate;
