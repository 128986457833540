import React, {useState, useEffect} from "react"
import "./Header.scss"
import DateRangePicker from "../../component/common/Daterangepicker/daterangepicker"
import Dropdown from "../../component/common/Dropdown/dropdown"
import {Link, useNavigate} from "react-router-dom"
import jwt_decode from "jwt-decode"
import {useDispatch, useSelector} from "react-redux"
import {USER_LOGOUT} from "../../redux/reducer/login"
import apiCall from "../../utils/apiFunction/apiCall"
import {apiConstants} from "../../constants/Common/apiConstants"
import { toast } from "react-toastify"

const Header = (props) => {
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  // const handleDateChange = (sDate, eDate) => {
  //   setStartDate(sDate);
  //   setEndDate(eDate);
  // };
  // reference for date range picker
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [accessData, setAccessData] = useState([])
  // const [ioAccess, setIoAccess] = useState([])
  const loginState = useSelector((state) => state.login)
  useEffect(() => {
    let userData = loginState?.userData
    if (userData?.length) {
      let tempUser = [...userData]
      if (loginState.decodedData.case_approval_flag == "1") {
        tempUser.push("case_details_approval")
      }
      setAccessData([...tempUser])
    }
  }, [])

  const getToken = () => {
    apiCall.post(apiConstants.ioToken, loginState.decodedData).then((res) => {
      window.open(apiConstants.IOURL + res.data.response.token, "_blank")
      dispatch(USER_LOGOUT())
    })
  }
  const handleLogout=()=>{
    apiCall.post(apiConstants?.LOGOUT).then((res) => {
      if (res?.data?.status === 200) {
        toast.success("session timeout")
        dispatch(USER_LOGOUT())
      } else {
        toast.error(res?.data?.message)
      }
    })
  }
  return (
    <div className="Header m-0 p-0 d-relative">
      <div className="image-div">
        <img src="/assests/images/tata-logo.svg" alt="Tata Logo" />
      </div>

      <div class="dropdown dropdown-right ">
        <button class=" dropdown-toggle larger_f_bold_title " type="button" data-toggle="dropdown">
          {`HI! ${loginState.decodedData?.user_name}`}&nbsp;
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          {/* <Link class="dropdown-item" to="#">
						View Profile
					</Link>
					<Link class="dropdown-item" to="#">
						Change Password
					</Link> */}
          {accessData.includes("I_O_SystemX") && (
            <Link
              class="dropdown-item Header_DropdownItem"
              onClick={() => {
                getToken()
                // dispatch(USER_LOGOUT())
              }}>
              Switch To IO
            </Link>
          )}
          <Link
            class="dropdown-item Header_DropdownItem"
            onClick={() => {
              handleLogout()    
            }}>
            Logout
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
